import React, { Suspense, lazy }  from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/globals.scss';
import Layout from './components/Layout/Layout';
import { Provider } from 'react-redux/es/exports';
import store from './redux/store';
import Dashboard from './pages/Dashboard/Dashboard';
import { getToken } from './service/tokenService';
import { getMe } from './redux/adminSlice/adminSlice';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const SignIn = lazy(() => import('./pages/SingIn/SingIn'));
const Orders = lazy(() => import('./pages/Orders/Orders'));
const Pricing = lazy(() => import('./pages/Pricing/Pricing'));
const PricingEdit = lazy(() => import('./pages/PricingEdit/PricingEdit'));
const Promocodes = lazy(() => import('./pages/Promocodes/Promocodes'));
const CreatePromocode = lazy(() => import('./pages/CreatePromocode/CreatePromocode'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout showSidebar={true}><Dashboard /></Layout>,
  },
  {
    path: "/sign-in",
    element: <Suspense fallback={<p>Loading...</p>}><Layout showSidebar={false}><SignIn/></Layout></Suspense>,
  },
  {
    path: "/orders",
    element: <Suspense fallback={<p>Loading...</p>}><Layout showSidebar={true}><Orders/></Layout></Suspense>,
  },
  {
    path: "/pricing",
    element: <Suspense fallback={<p>Loading...</p>}><Layout showSidebar={true}><Pricing/></Layout></Suspense>,
  },
  {
    path: "/pricing/edit/:airport",
    element: 
    <Suspense fallback={<p>Loading...</p>}>
      <Layout showSidebar={false}>
        <div className="container">
          <PricingEdit />
        </div>
      </Layout>
    </Suspense>,
  },
  {
    path: "/promocodes",
    element: 
    <Suspense fallback={<p>Loading...</p>}>
      <Layout showSidebar={true}>
          <Promocodes />
      </Layout>
    </Suspense>,
  },
  {
    path: "/promocodes/create",
    element: 
    <Suspense fallback={<p>Loading...</p>}>
      <Layout showSidebar={false}>
        <div className="container">
          <CreatePromocode />
        </div>
      </Layout>
    </Suspense>,
  },
]);

getToken() && store.dispatch(getMe());

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}/>
    </Provider>
  </React.StrictMode>
);

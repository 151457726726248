import React, { useEffect } from 'react';
import { useAppSelector } from '../../redux/redux-hooks';
import { admin } from '../../redux/selectors';
import { checkAuth } from '../../utils/checkAuth';
import styles from './dashboard.module.scss'

const Dashboard = () => {

    const { data, status } = useAppSelector(admin);

    useEffect(() => {
        checkAuth(data, status);
    }, [data, status])

  return (
    <main>
      <div className={styles.dashboardContent}>
        <div className={styles.dashboardMessage}>Welcome to Dashboard!</div>
      </div>
    </main>
  )
}

export default Dashboard
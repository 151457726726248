import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IAdmin } from "../../types/admin";
import { IStatus } from "../../types/status";
import { ISingInFromData } from "../../types/singInFromData";
import { api, authApi } from "../../api/api";
import { removeToken, setToken } from "../../service/tokenService";

const namespace: string = 'auth';

export const fetchLogin = createAsyncThunk(`${namespace}/login`, async (payload: ISingInFromData): Promise<IAdmin>  => {
    const { data } = await api.post<IAdmin>(`/${namespace}/login`, payload);

    setToken(data.accessToken);

    return data;
});

export const fetchLogout = createAsyncThunk(`${namespace}/logout`, async ({ id }: Pick<IAdmin, 'id'>): Promise<void>  => {
    const { data } = await authApi.post<void>(`/${namespace}/logout`, { id })

    return data;
});

export const getMe = createAsyncThunk(`${namespace}/get-me`, async (): Promise<IAdmin>  => {
    const { data } = await authApi.get<IAdmin>(`/${namespace}/get-me`);
    return data;
});

export interface IAdminState {
    data: IAdmin | null,
    status: IStatus
}

const initialState: IAdminState = {
    data: null,
    status: 'idle',
}

const adminSlice = createSlice({
    name: '@admin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLogin.pending, (state) => {
            state.data = null;
            state.status = 'pending';
        });
        builder.addCase(fetchLogin.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = 'fulfilled';
        });
        builder.addCase(fetchLogin.rejected, (state) => {
            state.data = null;
            state.status = 'rejected';
        });
        builder.addCase(getMe.pending, (state) => {
            state.data = null;
            state.status = 'pending';
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.data = action.payload;
            state.status = 'fulfilled';
        });
        builder.addCase(getMe.rejected, (state) => {
            state.data = null;
            state.status = 'rejected';
        });
        builder.addCase(fetchLogout.fulfilled, (state, action) => {
            state.data = null;
            state.status = 'logout';
            removeToken();
        });
    }
});

export const adminReducer = adminSlice.reducer;

export const {} = adminSlice.actions;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socials_socials__VJ2UR {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.socials_socialsItem__KIUdn {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/Socials/socials.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;AACJ","sourcesContent":[".socials{\n    display: flex;\n    align-items: center;\n    margin-top: 8px;\n}\n\n.socialsItem{\n    font-size: 12px;\n    font-weight: 700;\n    line-height: 18px;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socials": `socials_socials__VJ2UR`,
	"socialsItem": `socials_socialsItem__KIUdn`
};
export default ___CSS_LOADER_EXPORT___;

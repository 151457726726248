// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__A7NEZ {
  background: var(--yellow);
  padding: 21px 0px;
}

.header_headerContent__gPg-K {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.header_headerContacts__OGo8T {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,8BAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;AACJ","sourcesContent":[".header{\n    background: var(--yellow);\n    padding: 21px 0px;\n}\n\n.headerContent{\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n}\n\n.headerContacts{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__A7NEZ`,
	"headerContent": `header_headerContent__gPg-K`,
	"headerContacts": `header_headerContacts__OGo8T`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import styles from './socials.module.scss'

const Socials = () => {
  return (
    <div className={styles.socials}>
        <a href="" className={styles.socialsItem}>WhatsUp</a>,
        <a href="" className={styles.socialsItem}> Telegram</a>
    </div>
  )
}

export default Socials
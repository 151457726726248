// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidebar__-jiIa {
  width: 228px;
  padding: 16px 16px;
  height: calc(100vh - 88px);
}

.sidebar_sideBarLink__gsoWo {
  display: block;
  padding: 14px 16px;
  transition: 0.4s;
}
.sidebar_sideBarLink__gsoWo:hover {
  background: rgb(237, 231, 246);
  color: rgb(94, 53, 177);
  border-radius: 12px;
}
.sidebar_sideBarLink__gsoWo:not(:first-child) {
  margin-top: 4px;
}

.sidebar_sideBarLinkActive__1igqB {
  display: block;
  padding: 14px 16px;
  background: rgb(237, 231, 246);
  color: rgb(94, 53, 177);
  border-radius: 12px;
}

.sidebar_sidebarNav__RMf0M {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,0BAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AACI;EACI,8BAAA;EACA,uBAAA;EACA,mBAAA;AACR;AAEI;EACI,eAAA;AAAR;;AAIA;EACI,cAAA;EACA,kBAAA;EACA,8BAAA;EACA,uBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,gBAAA;AADJ","sourcesContent":[".sidebar{\n    width: 228px;\n    padding: 16px 16px;\n    height: calc(100vh - 88px);\n}\n\n.sideBarLink{\n    display: block;\n    padding: 14px 16px;\n    transition: 0.4s;\n\n    &:hover{\n        background: rgb(237, 231, 246);\n        color: rgb(94, 53, 177);\n        border-radius: 12px;\n    }\n\n    &:not(:first-child){\n        margin-top: 4px;\n    }\n}\n\n.sideBarLinkActive{\n    display: block;\n    padding: 14px 16px;\n    background: rgb(237, 231, 246);\n    color: rgb(94, 53, 177);\n    border-radius: 12px;\n}\n\n.sidebarNav{\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `sidebar_sidebar__-jiIa`,
	"sideBarLink": `sidebar_sideBarLink__gsoWo`,
	"sideBarLinkActive": `sidebar_sideBarLinkActive__1igqB`,
	"sidebarNav": `sidebar_sidebarNav__RMf0M`
};
export default ___CSS_LOADER_EXPORT___;

import axios from "axios";
import { getToken } from "../service/tokenService";

const serverUrl = 'https://server.kalimera.vip';
// const serverUrl = 'http://localhost:5500';

// const serverUrl = 'http://138.201.116.147:5500';



const api = axios.create({
    baseURL: `${serverUrl}/api`
});

const authApi = axios.create({
    baseURL: `${serverUrl}/api`,
    headers: {
        Authorization: 'Bearer ' + getToken()
    }
});

export {
    api, serverUrl, authApi
};

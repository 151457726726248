import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar';

interface ILayout {
    children: React.ReactNode;
    showSidebar: boolean;
}

const Layout = ({ children, showSidebar }: ILayout) => {
  return (
    <>
    <Header />
    {
    showSidebar
    ?
    <div style={{display: 'flex'}}>
      <Sidebar />
      {children}
    </div>
    :
    children
    }
    </>
  )
}

export default Layout
import React from 'react'
import { useLocation } from 'react-router';
import styles from './sidebar.module.scss'

const navigation = [
    {id: 1, name: 'Orders', href: '/orders'},
    {id: 2, name: 'Pricing', href: '/pricing'},
    {id: 3, name: 'Promocodes', href: '/promocodes'}
];
const Sidebar = () => {

  const location = useLocation();

  return (
    <aside className={styles.sidebar}>
        <div className="sidebarName">Dashboard</div>
        <nav className={styles.sidebarNav}>
            {navigation.map((el) => (
              el.href === location.pathname
              ?
              <a href={el.href} className={styles.sideBarLinkActive} key={el.id}>{el.name}</a>
              :
              <a href={el.href} className={styles.sideBarLink} key={el.id}>{el.name}</a>
            ))}
        </nav>
    </aside>
  )
}

export default Sidebar
import { IAdmin } from "../types/admin"

const setToken = (token: string) => {
    localStorage.setItem('kalimera-access-token', token);
};

const getToken = () => {
    return localStorage.getItem('kalimera-access-token');
};

const removeToken = () => {
    return localStorage.removeItem('kalimera-access-token');
};

export {
    setToken,
    getToken,
    removeToken
}
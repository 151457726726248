import React from 'react'
import Logotype from '../../images/svg/logo.svg';

const Logo = () => {
  return (
    <a href="/" className="logo">
        <img src={Logotype} alt="Kalimera" width={195} height={48} />
    </a>
  )
}

export default Logo
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_dashboardMessage__Ip8pi {
  font-weight: 700;
  font-size: 48px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/dashboard.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ","sourcesContent":[".dashboardMessage{\n    font-weight: 700;\n    font-size: 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardMessage": `dashboard_dashboardMessage__Ip8pi`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import Logo from '../Logo/Logo'
import styles from './header.module.scss';
import Telephone from '../Telephone/Telephone';
import Socials from '../Socials/Socials';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.headerContent}>
          <Logo />
          <div className={styles.headerContacts}>
            <Telephone />
            <Socials/>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
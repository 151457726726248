
import { IAdmin } from "../types/admin";
import { IStatus } from "../types/status";

export const checkAuth = (data: IAdmin | null, status: IStatus) => {
    if(!data && status === 'idle'){
        return window.location.href = '/sign-in'
    }else if(!data && status === 'rejected'){
        return window.location.href = '/sign-in'
    }else if(!data && status === 'logout'){
        return window.location.href = '/sign-in'
    }
}